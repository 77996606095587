import React, {createContext} from 'react'
import Axios, {AxiosInstance} from 'axios'
import {CreateAxios} from '@/hooks/axios'

type FetchContextState = {
  axios: AxiosInstance
}

const initialState: FetchContextState = {
  axios: Axios,
}
const FetchContext = createContext<FetchContextState>(initialState)

export const FetchProvider: React.FC = ({children}) => {
  const axios = CreateAxios()
  return (
    <FetchContext.Provider value={{axios}}>
      {children}
    </FetchContext.Provider>
  )
}
