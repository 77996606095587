import {apiConfig} from '@/env'
import Axios, {AxiosInstance} from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {useCallback, useEffect, useState} from 'react'
import {configure} from 'axios-hooks'

export function CreateAxios(): AxiosInstance {
  const [accessToken, setAccessToken] = useState<string>()
  const {getAccessTokenSilently} = useAuth0()

  const getAccessToken = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently()
      setAccessToken(token)
    } catch (err) {
      console.log(err)
    }
  }, [getAccessTokenSilently])

  useEffect(() => {
    getAccessToken()
  }, [getAccessToken])

  const axios = Axios.create({
    baseURL: apiConfig.apiEndpoint,
  })

  axios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${accessToken}`
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  configure({axios})
  return axios
}
