/* eslint-disable @typescript-eslint/consistent-type-definitions */

import {createMuiTheme} from '@material-ui/core/styles'
import {lightBlue} from '@material-ui/core/colors'


type Status = {
  started: string
  waiting: string
  finished: string
  error: string
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    status: Status
    contentBackground: string
  }

  interface PaletteOptions {
    status?: Partial<Status>
    contentBackground?: string
  }
}

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true
    sm: false
    md: false
    lg: true
    xl: false
  }
}

export const defaultTheme = createMuiTheme({
  spacing: (factor) => factor * 4,
  breakpoints: {
    values: {
      xs: 0,
      lg: 560,
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Noto Sans JP'].join(','),
    fontSize: 14,
    h1: {
      fontSize: '2.285rem',
      fontWeight: 'bold',
      lineHeight: 1.5,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 'bold',
      lineHeight: 1.5,
    },
    h3: {
      fontSize: '1.57rem',
      fontWeight: 'bold',
      lineHeight: 1.5,
    },
    h4: {
      fontSize: '1.285rem',
      fontWeight: 'bold',
      lineHeight: 1.5,
    },
    subtitle1: {
      fontSize: '1.07rem',
      fontWeight: 'bold',
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: '1rem',
      fontWeight: 'bold',
      lineHeight: 1.5,
    },
    body1: {
      fontSize: '1.07rem',
      lineHeight: 1.6,
    },
    body2: {
      fontSize: '1rem',
      lineHeight: 1.6,
    },
    caption: {
      fontSize: '0.857rem',
      fontWeight: 'bold',
      lineHeight: 1.5,
    },
    overline: {
      fontSize: '0.857rem',
      lineHeight: 1.5,
      textTransform: 'none',
    },
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    status: {
      started: '#f3c000',
      waiting: '#467654',
      finished: '#489fb5',
      error: '#f44336',
    },
    primary: {
      main: '#55C341',
      dark: '#409431',
      contrastText: 'rgba(255, 255, 255, 0.9)',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.9)',
      secondary: 'rgba(255, 255, 255, 0.6)',
      disabled: 'rgba(255, 255, 255, 0.38)',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    contentBackground: '#232323',
    divider: 'rgba(255, 255, 255, 0.12)',
    action: {
      disabled: '#505050',
      disabledBackground: '#6c6c6c',
    },
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
    MuiCheckbox: {
      color: 'primary',
    },
    MuiRadio: {
      color: 'primary',
    },
    MuiSwitch: {
      color: 'primary',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          webkitFontSmoothing: 'auto',
          lineHeight: 1.5,
          fontSize: 14,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#545454',
        },
      },
    },
    MuiIconButton: {
      root: {
        color: 'secondary',
      },
    },
    MuiPickersToolbar: {
      root: {
        backgroundColor: lightBlue.A200,
      },
    },
    MuiPickersCalendarHeader: {
      root: {
        backgroundColor: lightBlue.A200,
        color: 'white',
      },
      dayLabel: {
        color: '#545454',
      },
    },
    MuiPickersDay: {
      root: {
        color: lightBlue.A700,
        '&$disabled': {
          color: lightBlue['100'],
        },
        '&$selected': {
          backgroundColor: lightBlue['400'],
        },
      },
      today: {
        color: lightBlue['900'],
      },
    },
    MuiPickersModalDialog: {
      dialogAction: {
        color: lightBlue['400'],
      },
    },
  },
})
