import {ClientStateProvider, MyApolloProvider, useApollo} from '@/hooks/apollo'
import App from 'next/app'
import React from 'react'
import Head from 'next/head'
import * as Sentry from '@sentry/node'

import type {
  AppProps,
} from 'next/app'

import {
  ThemeProvider,
} from '@material-ui/core/styles'

import CssBaseline from '@material-ui/core/CssBaseline'

import {
  defaultTheme,
} from '@/themes'

import '../styles/global.css'

import {
  LoadingIndicator,
} from '@/components'
import {useRouter} from 'next/router'
import {auth0Config, sentryConfig} from '@/env'
import {Auth0Provider} from '@auth0/auth0-react'
import {AppState} from '@auth0/auth0-react/src/auth0-provider'
import {RewriteFrames} from '@sentry/integrations'
import {SnackbarProvider} from 'notistack'
import {DevContextProvider} from '@/hooks/development/context'
import {FetchProvider} from '@/hooks/axios'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    enabled:  process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename?.replace(sentryConfig.distDir, 'app:///_next')
          return frame
        },
      }),
    ],
    dsn: sentryConfig.dsn,
    tracesSampleRate: 1.0,
  })
}

const MyApp = (props: AppProps) => {
  const {Component, pageProps} = props
  const router = useRouter()

  const auth0 = auth0Config

  const onRedirectCallback = (appState: AppState|undefined) => {
    (async () => {
      const redirect = appState?.returnTo || '/'
      await router.replace(`/signup?redirect=${redirect}`)
    })()
  }

  // SSR用にinjectしたCSSを削除する (以降はクライアント側のstateに従ってレンダリングするため邪魔になる)
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles)
    }
  }, [])

  return (
    <ClientStateProvider>
      <DevContextProvider>
        <Auth0Provider
          domain={auth0.domain}
          clientId={auth0.clientId}
          redirectUri={auth0.redirectUri}
          onRedirectCallback={onRedirectCallback}
          scope={auth0.scope}
          audience={auth0.audience}
          cacheLocation='localstorage'
          useRefreshTokens={true}
        >
          <MyApolloProvider pageProps={pageProps}>
            <FetchProvider>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <Head>
                  <title>Adictor</title>
                  <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, user-scalable=no" />
                  <meta name="description" content="Adictorは、日本初の全大会賞金付きeスポーツ大会プラットフォームです。多くの大会にエントリー、上位入賞して特典を勝ち取りましょう。"/>
                  <meta name="twitter:title" content="Adictor | 全大会賞金付きeスポーツ大会プラットフォーム"/>
                  <meta name="twitter:description" content="Adictorは、日本初の全大会賞金付きeスポーツ大会プラットフォームです。多くの大会にエントリー、上位入賞して特典を勝ち取りましょう。"/>
                  <meta name="twitter:card" content="summary_large_image"/>
                  <meta name="twitter:site" content="@AdictorOfficial"/>
                  <meta property="og:image" key="ogimage" content="https://asset.adictor.jp/default/ogimage.png"/>
                </Head>
                <LoadingIndicator />
                <ThemeProvider theme={defaultTheme}>
                  <CssBaseline />
                  <Component {...pageProps} />
                </ThemeProvider>
              </SnackbarProvider>
            </FetchProvider>
          </MyApolloProvider>
        </Auth0Provider>
      </DevContextProvider>
    </ClientStateProvider>
  )

}

// Automatic Static Generation により router.query が空になるのを回避するため無駄な getInitialProps を定義する
// https://github.com/vercel/next.js/issues/10521
// https://nextjs.org/docs/advanced-features/automatic-static-optimization#caveats
MyApp.getInitialProps = async (appContext: any) => {
  const appProps = await App.getInitialProps(appContext)
  return {...appProps}
}

export default MyApp
